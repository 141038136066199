import getCustomIntegrations from '../scripts/sentryIntegrations.js'

import merge from '~lodash.mergewith'
import { init, vueRouterInstrumentation, BrowserTracing } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export async function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002Fc35afbb8e8fe4a75a53959e14805c9a8@o239809.ingest.sentry.io\u002F1410890",
    environment:"etg24.de",
    ignoreErrors:["PDFJSWrapper","Rendering cancelled, page","h.cancel().catch","Cannot read properties of undefined (reading 'catch')","Avoided redundant navigation","Synchronous XMLHttpRequest on the main thread is deprecated"],
    sampleRate:0.75,
    tracesSampleRate:0.01,
    release:"eded237ae46177d93d858c26f23dff561831e8d6",
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
  ]

  const { browserTracing, vueOptions, vueRouterInstrumentationOptions, ...tracingOptions } = {"tracesSampleRate":0.01,"browserTracing":{},"vueOptions":{"trackComponents":true},"vueRouterInstrumentationOptions":{"routeLabel":"name"}}
  config.integrations.push(new BrowserTracing({
    ...(ctx.app.router ? { routingInstrumentation: vueRouterInstrumentation(ctx.app.router, vueRouterInstrumentationOptions) } : {}),
    ...browserTracing,
  }))
  merge(config, vueOptions, tracingOptions)

  const customIntegrations = await getCustomIntegrations(ctx)
  if (Array.isArray(customIntegrations)) {
    config.integrations.push(...customIntegrations)
  } else {
    console.error(`[@nuxtjs/sentry] Invalid value returned from customClientIntegrations plugin. Expected an array, got "${typeof customIntegrations}".`)
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
